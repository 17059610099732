const databases_to_filter_out = ['New', 'Type'];

export function get_databases() {
  const user_info = globalThis.user_data.info;
  if (!user_info) {
    return {};
  }

  const { permission = undefined } = user_info;
  if (!permission) {
    return {};
  }

  const db_names_and_keys = {};
  permission.forEach((p) => {
    db_names_and_keys[p[0][1]] = p[0][0];
  });

  databases_to_filter_out.forEach((dbname) => {
    delete db_names_and_keys[dbname];
  });

  return db_names_and_keys;
}
