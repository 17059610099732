export function highlight_clicks() {
  window.addEventListener('click', (e) => {
    const div = document.createElement('div');
    const y = e.clientY;
    const x = e.clientX;
    const style = `position: fixed; top: ${y - 80 / 2}px; left:${x - 80 / 2}px; 
  transition: all 0.3s ease-in-out;`;

    div.setAttribute('style', `${style}; transform:scale(0);`);
    div.setAttribute('class', `w-[80px] h-[80px] z-500 rounded-full border-4 border-red-600`);
    document.body.appendChild(div);
    setTimeout(() => div.setAttribute('style', `${style}; transform:scale(1);`), 1);
    setTimeout(() => div.setAttribute('style', `${style}; transform:scale(1); opacity:0;`), 150);
    setTimeout(() => div.setAttribute('style', `${style}; transform:scale(0); opacity:0;`), 300);
    setTimeout(() => div.remove(), 500);
  });
}
