import { rpc } from '../crystal_api/rpc';
import { show_ephemeral_toast } from '../toast';

export async function add_doc_to_overlay_table(row, option) {
  const adding_id = row.getData()['_id'];
  if (!option._in_dest_table) {
    option._in_dest_table = {};
  }
  if (option._in_dest_table[adding_id]) {
    if (option._in_dest_table[adding_id] == 1) {
      const msg = report_model ? 'Report already in table' : 'materials-duplicate';
      show_ephemeral_toast(_(msg), 'Error');
    }
    option._in_dest_table[adding_id] += 1;
    return;
  }
  option._in_dest_table[adding_id] = 1;
  const dest_new_current = option.new_current;
  remove_default_row(option);
  const in_table = dest_new_current.map((row) => row[0][0]);
  if (in_table.includes(adding_id)) {
    const msg = report_model ? 'Report already in table' : 'materials-duplicate';
    show_ephemeral_toast(_(msg), 'Error');
    return;
  }
  const doc = await rpc.doc({ uid: adding_id }).then((value) => {
    return value;
  });
  const dest_handle = option.handle;
  const target_with_subCmp = !['MaterialOxide', 'MaterialDummy'].includes(doc['class_name']);
  const materials_with_subCmp = !['MaterialRaw', 'MaterialMix', 'MaterialDummy'].includes(doc['class_name']);
  const report_model = ['Report', 'Model'].some((doc_type) => doc['class_name'].startsWith(doc_type));
  if (target_with_subCmp && dest_handle === 'target') {
    add_composition_to_formulation_target(doc, dest_new_current, in_table);
  } else if (materials_with_subCmp && dest_handle === 'materials') {
    add_composition_to_formulation_materials(doc, dest_new_current, in_table);
  } else if (report_model && dest_handle === 'tests') {
    const report_type = in_table.length ? in_table[0].split('R:')[1].split('#')[0] : '';
    add_report_to_model_tests(doc, dest_new_current, in_table, report_type, option);
  } else {
    const new_row = option.table_new_row()[0];
    new_row[0] = [adding_id, doc['name']];
    dest_new_current.push(new_row);
    in_table.push(adding_id);
  }
  if (option.validate_new_current(dest_new_current)) {
    update_destination_table(dest_new_current, option);
  }
  return dest_new_current;
}

function add_composition_to_formulation_materials(doc, dest_new_current, in_table) {
  if (!doc['cmp_semi']) {
    console.log('invalid material');
    return;
  }
  for (let sub_row of doc['cmp_semi']) {
    const doc_id = sub_row[0][0];
    if (in_table.includes(doc_id)) {
      show_ephemeral_toast(_('materials-duplicate'), 'Error');
      continue;
    }
    sub_row = [sub_row[0], 0, 100, 0];
    dest_new_current.push(sub_row);
    in_table.push(doc_id);
  }
}

function add_composition_to_formulation_target(doc, dest_new_current, in_table) {
  const sub_cmp = doc['cmp_oxide'].length ? 'cmp_oxide' : 'cmp_mix_oxide';
  console.log('Adding oxides');
  for (let sub_row of doc[sub_cmp]) {
    const doc_id = sub_row[0][0];
    if (in_table.includes(doc_id)) {
      const table_idx = in_table.indexOf(doc_id);
      const material_already_set = dest_new_current[table_idx].slice(1).every((el) => el === 0);
      if (material_already_set) {
        dest_new_current[table_idx] = sub_row;
      } else {
        show_ephemeral_toast(_('materials-duplicate'), 'Error');
      }
    } else {
      dest_new_current.push(sub_row);
      in_table.push(doc_id);
    }
  }
}

const report_name = {
  ReportHorizontal: 'horizontal dilatometer',
  ReportVertical: 'vertical dilatometer',
  ReportFlex: 'fleximeter',
  ReportMicroscope: 'heating microscope',
  ReportKiln: 'kiln',
};

function get_adding_report_type(doc) {
  return doc['class_name'].startsWith('Model') ? doc['tests'][0][0][0].split(':')[1].split('#')[0] : doc['class_name'];
}

function add_report_to_model_tests(doc, dest_new_current, in_table, report_type, option) {
  const doc_id = doc['_id'];
  const adding_type = get_adding_report_type(doc);
  if (in_table.includes(doc_id)) {
    show_ephemeral_toast(_('Report already in table'), 'Error');
    return;
  }
  if (report_type && adding_type !== report_type) {
    show_ephemeral_toast(_(`Only <b>${report_name[report_type]}</b> reports can be added`), 'Error');
    return;
  }
  if (doc['class_name'].startsWith('Model')) {
    for (let test of doc['tests']) {
      dest_new_current.push(test);
      in_table.push(test[0]);
    }
  } else {
    const new_row = option.table_new_row()[0];
    new_row[0] = [doc_id, doc['name']];
    dest_new_current.push(new_row);
    in_table.push(doc_id);
  }
}

function remove_default_row(destination) {
  const rows = destination.table.getRows();
  if (rows.length) {
    const first_column_handle = destination.header[0]['handle'];
    let first_row_value = rows[0].getData()[first_column_handle];
    if (first_row_value === 'default') {
      destination.table.deleteRow(rows[0]);
      destination.new_current.shift();
    }
  }
}

function update_destination_table(dest_new_current, option) {
  option.new_current = dest_new_current;
  const items = option.value_to_user(dest_new_current);
  option.table.clearData();
  items.forEach((item, idx) => {
    option.table.addRow(item, false, items[idx - 1]);
  });
  option.table.setPageSize(option.new_current.length);
}
