import { v4 as uuid_v4 } from 'uuid';

function show_toast(message, title = 'Info', auto_hide = true) {
  const toast_template = require('../view/toast.handlebars');
  const id = uuid_v4();
  hide_visible_toast();
  const toast_html = toast_template({
    toast_id: id,
    toast_message: _(message),
    toast_title: title,
    toast_auto_hide: auto_hide,
  });

  document.body.insertAdjacentHTML('beforeend', toast_html);
}

export function show_sticky_toast(message, title) {
  show_toast(message, title, false);
}

export function show_ephemeral_toast(message, title) {
  show_toast(message, title, true);
}

export function hide_visible_toast() {
  document.querySelectorAll('[role="alert"]').forEach((e) => e.remove());
}

export function show_toast_from_cookie() {
  const message = get_cookie('toast_message');
  const title = get_cookie('toast_title');
  delete_cookie('toast_message');
  delete_cookie('toast_title');
  if (message) {
    setTimeout(() => {
      show_ephemeral_toast(message, title);
    }, 250);
  }
}

export function save_toast_message_in_cookie(message, title) {
  set_cookie('toast_title', _(title));
  set_cookie('toast_message', _(message));
}
