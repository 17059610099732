export function tabulator_options(ajax_params, ajax_request_func, auto_columns_definitions, load_all) {
  return {
    height: 690,
    ajaxURL: globalThis.rpc_config.url,
    ajaxConfig: {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json; charset=utf-8',
      },
    },
    progressiveLoad: load_all ? 'load' : 'scroll',
    ajaxParams: ajax_params,
    ajaxRequestFunc: ajax_request_func,
    dataSendParams: { size: 'limit' },
    paginationSize: globalThis.default_page_size,
    layout: 'fitDataFill',

    placeholder: `<span data-cy="no-tests-found" x-text='_("No documents found")'></span>`,
    autoColumns: true,
    autoColumnsDefinitions: auto_columns_definitions,
    downloadConfig: {
      columnCalcs: false,
    },
  };
}
