import { get_selected_db_id } from '../common/hash';
import { ligo_search_report } from '../ligo/search_report_functions';
import { populate_genome_search_form } from './ui';

export function is_current_db_report() {
  return get_selected_db_id().endsWith('R');
}

export function search_document_options() {
  return is_current_db_report() ? globalThis.report_search_doc.options : globalThis.search_genome_object.options;
}

export function get_search_type() {
  return is_current_db_report() ? 'SearchReport' : 'SearchGenome';
}

export function get_load_callback() {
  return is_current_db_report() ? ligo_search_report : populate_genome_search_form;
}
