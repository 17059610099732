function create_python_files_skipping_tests(require, pyodide) {
  require
    .keys()
    .filter((filename) => !filename.includes('/tests/'))
    .forEach((filename) => {
      pyodide.runPython(
        `import os; os.makedirs(os.path.dirname('${filename}'), exist_ok=True);`
      );
      pyodide.FS.writeFile(filename, require(filename), {
        encoding: 'utf8',
      });
    });
}

function sync_persisted_data(FS, read_from_persisted_data) {
  return new Promise((resolve, reject) => {
    FS.syncfs(read_from_persisted_data, (err) => {
      if (err) reject();
      else resolve();
    });
  });
}
function write_to_persisted_data(FS) {
  return sync_persisted_data(FS, false);
}

function read_from_persisted_data(FS) {
  return sync_persisted_data(FS, true);
}

function loadPyodide() {
  return import('pyodide/pyodide.js').then((pyodide_pkg) => {
    return pyodide_pkg
      .loadPyodide({
        indexURL: `${window.location.origin}/pyodide`,
      })
      .then((pyodide) => {
        globalThis.pyodide = pyodide;
        pyodide.init = () => {
          pyodide.runPython('import entrypoint');
          return pyodide;
        };

        const pyodide_home = '/home/pyodide/';
        pyodide.FS.mount(pyodide.FS.filesystems.IDBFS, {}, pyodide_home);

        return read_from_persisted_data(pyodide.FS).then(() => {
          const needsUpdatingPythonFiles =
            DEV || !pyodide.FS.analyzePath(VERSION).exists;
          if (needsUpdatingPythonFiles) {
            pyodide.FS.unmount(pyodide_home);
            pyodide.FS.mount(pyodide.FS.filesystems.IDBFS, {}, pyodide_home);
            create_python_files_skipping_tests(
              require.context('../../pygenome/', true, /\.py$/),
              pyodide
            );
            pyodide.FS.writeFile(VERSION, '');

            return write_to_persisted_data(pyodide.FS).then(() => pyodide);
          }

          return pyodide;
        });
      });
  });
}

export default await loadPyodide();
