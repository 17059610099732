import { rpc } from './crystal_api/rpc';

function optimize_payload(last_query) {
  delete last_query.list;
}

export function ajaxRequestFunc(
  parse_new_data_function = (r) => [r.list, r.list],
  initial_last_query = { cmd: 'list' },
  queryHash_already_set = false
) {
  let last_query = initial_last_query;

  return (url, config, params) => {
    optimize_payload(last_query);
    let query_params = last_query;

    query_params.user = '';

    query_params.logsessid = globalThis.get_cookie('logsessid');

    query_params.limit = params.limit;

    query_params.page = params.page || 1;

    query_params.db = params.search_db || globalThis.get_cookie(globalThis.last_db_cookie_name);

    return new Promise(function (resolve) {
      let callback = function (resp) {
        let data;
        [data, last_query] = parse_new_data_function(resp);
        let last_page = query_params.page;
        if (data.length >= query_params.limit) {
          last_page += 1;
        }

        queryHash_already_set || set_query_param('queryHash', resp.queryHash);
        queryHash_already_set = true;

        resolve({ last_page: last_page, data: data });
        dispatch_event('ajax-request-finished');
      };

      dispatch_event('ajax-request-started');
      rpc[params['cmd']](query_params).then(callback);
    });
  };
}
