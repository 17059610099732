import { _ } from './translations';
import { is_report_selected } from './hash';

function create_navbar_elements() {
  const new_document = ['Material', 'Analysis', 'Misc', 'Model'];
  const xdata = new_document.reduce((acc, name) => {
    acc[`new${name}_menu`] = false;
    return acc;
  }, {});
  if (is_report_selected() || is_report_selected(get_cookie('last_db'))) {
    xdata['newModel_menu'] = true;
  } else {
    xdata['newMaterial_menu'] = true;
  }
  document.getElementById('navbar').setAttribute('x-data', JSON.stringify(xdata));

  import('./media/Icona.png').then((icon) => (document.getElementById('app-icon').src = icon.default));
}

export { create_navbar_elements };
