import { map_object } from './utils';

const listeners_already_added_for_cam = {};
const scales = {};
const samples_urls = {};

export const image_selection = (cam_id, sample_url, image_container, worker, server_url, scale = 1) => {
  samples_urls[cam_id] = sample_url;
  scales[cam_id] = scale;
  let is_selecting = false;
  let is_dragging_selection = false;
  let startX, startY, endX, endY;
  let selection_box_startX, selection_box_startY;
  let intersection_for_mdf = {};
  worker.read_server_roi = true;

  let is_border_left_selected = false;
  let is_border_right_selected = false;
  let is_border_top_selected = false;
  let is_border_bottom_selected = false;

  function clicked_border(clientX, clientY, selection_box) {
    const selection_box_rect = selection_box.getBoundingClientRect();
    const DELTA = 15;
    is_border_left_selected = Math.abs(selection_box_rect.x - clientX) < DELTA;
    is_border_right_selected = Math.abs(selection_box_rect.x + selection_box_rect.width - clientX) < DELTA;
    is_border_top_selected = Math.abs(selection_box_rect.y - clientY) < DELTA;
    is_border_bottom_selected = Math.abs(selection_box_rect.y + selection_box_rect.height - clientY) < DELTA;
    is_dragging_selection =
      !is_border_left_selected && !is_border_right_selected && !is_border_top_selected && !is_border_bottom_selected;
  }

  const selection_box = document.getElementById(`${cam_id}_selection_box`);

  if (!listeners_already_added_for_cam[cam_id]) {
    listeners_already_added_for_cam[cam_id] = true;
    selection_box.addEventListener('mousedown', (e) => {
      e.stopPropagation();
      worker.read_server_roi = false;
      clicked_border(e.clientX, e.clientY, selection_box);
      selection_box_startX = e.clientX;
      selection_box_startY = e.clientY;
    });

    image_container.addEventListener('mousemove', (e) => {
      let exit = true;
      const deltaX = e.clientX - selection_box_startX;
      const deltaY = e.clientY - selection_box_startY;
      selection_box_startX = e.clientX;
      selection_box_startY = e.clientY;
      const currentX = parseInt(selection_box.style.left) + deltaX;
      const currentY = parseInt(selection_box.style.top) + deltaY;
      const currentWidth = parseInt(selection_box.style.width);
      const currentHeight = parseInt(selection_box.style.height);

      if (is_border_left_selected) {
        selection_box.style.width = currentWidth - deltaX + 'px';
        selection_box.style.left = currentX + 'px';
        exit = false;
      }
      if (is_border_right_selected) {
        selection_box.style.width = currentWidth + deltaX + 'px';
        exit = false;
      }
      if (is_border_top_selected) {
        selection_box.style.height = currentHeight - deltaY + 'px';
        selection_box.style.top = currentY + 'px';
        exit = false;
      }
      if (is_border_bottom_selected) {
        selection_box.style.height = currentHeight + deltaY + 'px';
        exit = false;
      }

      if (!is_selecting && is_dragging_selection) {
        selection_box.style.left = currentX + 'px';
        selection_box.style.top = currentY + 'px';
        exit = false;
      }
      if (exit) {
        return;
      }
      const current_selection = selection_box.getBoundingClientRect();
      const image_box = image_container.getBoundingClientRect();

      intersection_for_mdf = {
        x: current_selection.left - image_box.x,
        y: current_selection.top - image_box.y,
        width: current_selection.width,
        height: current_selection.height,
      };
    });

    document.addEventListener('mousedown', (e) => {
      if (e.target.id === `${cam_id}_image_container`) {
        is_selecting = true;
        worker.read_server_roi = false;
        startX = e.clientX;
        startY = e.clientY;
      }
    });

    document.addEventListener('mousemove', (e) => {
      if (e.target.id === `${cam_id}_image_container` && is_selecting && !is_dragging_selection) {
        selection_box.hidden = false;
        endX = e.clientX;
        endY = e.clientY;
        const left = Math.min(startX, endX);
        const top = Math.min(startY, endY);
        const width = Math.abs(endX - startX);
        const height = Math.abs(endY - startY);

        const image_box = image_container.getBoundingClientRect();
        const intersection = {
          x: Math.max(image_box.x, left),
          y: Math.max(image_box.y, top),
          width: Math.min(image_box.x + image_box.width, left + width) - Math.max(image_box.x, left),
          height: Math.min(image_box.y + image_box.height, top + height) - Math.max(image_box.y, top),
        };

        selection_box.style.left = intersection.x + 'px';
        selection_box.style.top = intersection.y + 'px';
        selection_box.style.width = intersection.width + 'px';
        selection_box.style.height = intersection.height + 'px';
        intersection_for_mdf = {
          x: intersection.x - image_box.x,
          y: intersection.y - image_box.y,
          width: intersection.width,
          height: intersection.height,
        };
      }
    });

    document.addEventListener('mouseup', (e) => {
      is_selecting = false;
      is_dragging_selection = false;
      is_border_bottom_selected = false;
      is_border_top_selected = false;
      is_border_right_selected = false;
      is_border_left_selected = false;
      if (!intersection_for_mdf) return;
      if (e.target.id === `${cam_id}_selection_box` || e.target.id === `${cam_id}_image_container`) {
        const scaled_selection = map_object(intersection_for_mdf, (v) => v * scales[cam_id]);
        worker.postMessage({
          sample_url: samples_urls[cam_id],
          selection_box: scaled_selection,
          server_url: server_url,
        });
        worker.read_server_roi = true;
      }
    });
  }

  return selection_box;
};

globalThis.zoom_cam = (delta, x, y, element) => {
  console.log('zoom_cam');
  console.log(delta, x, y, element);
};
