import { hide_element } from './ui';

export function clean_cookies() {
  globalThis.delete_cookie('logsessid');
  globalThis.delete_cookie('user');
}

globalThis.do_logout = () => {
  console.log('logging out');
  hide_element('navbar_main_div');
  clean_cookies();
  globalThis.location.href = '/';
};
