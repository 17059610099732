import { capitalize_string, classname_formatter, cost_formatter, round_formatter, timestamp_formatter } from '../utils';
import { table_version_selector_formatter } from '../versions_manager';
import { is_genome_selected } from '../hash';

let tooltip = '';

export function table_columns_definition(default_tooltip = '', overlay = false) {
  tooltip = default_tooltip;
  const columns_to_remove = [];
  if (overlay) {
    is_genome_selected()
      ? columns_to_remove.push('_id', 'modified', 'version')
      : columns_to_remove.push('testid', 'price', 'cost', 'billRate', 'cumulative', 'maxTemp', '_id', 'discount');
  } else {
    columns_to_remove.push('testid', 'serial', 'price', 'modified', 'related', 'duplicates', 'thermal', 'curveHash');
    if (is_genome_selected()) columns_to_remove.push('_id');
  }
  const formatters = {
    maxTemp: round_formatter,
    cost: cost_formatter,
    cumulative: cost_formatter,
    created: timestamp_formatter,
    billRate: round_formatter,
    elapsed: round_formatter,
    _id: () => {
      return `<img class="h-5 w-5 cursor-pointer bg-no-repeat bg-auto" src='${require('../media/browser.png')}'>`;
    },
    class_name: classname_formatter,
    version: table_version_selector_formatter,
  };

  const sorters = { created: 'date' };

  const sorter_params = {
    created: { format: 'DD/MM/YYYY hh:mm:ss', alignEmptyValues: 'top' },
  };

  const name_conversions = {
    maxTemp: '°C',
    billRate: '°C/min',
    elapsed: 'duration',
    discount: '%',
    created: 'date',
    _id: 'Open',
    class_name: 'type',
  };

  const bottom_calcs = {
    created: 'count',
    elapsed: 'avg',
    discount: 'avg',
    cost: 'sum',
    cumulative: 'max',
    billRate: 'avg',
    name: 'count',
  };

  const max_widths = { _id: '40', class_name: '40' };

  const custom_cell_clicks = {
    _id: (e, cell) => {
      e.stopPropagation();
      const report_uid = globalThis.tests_table_list[cell.getRow().getPosition() - 1]['_id'];
      let report_path = get_document_hash(report_uid);

      globalThis.location.href = `mdf://${globalThis.location.host}/${report_path}`;
    },
  };

  const downloads = { _id: "false" };

  return function (definitions) {
    if (!is_genome_selected() || definitions.some((k) => k['field'] === 'testid')) {
      const classname_col_idx = get_column_index(definitions, 'class_name');
      const name_col_idx = get_column_index(definitions, 'name');
      const classname_column = definitions.splice(classname_col_idx, 1)[0];
      definitions.splice(name_col_idx, 0, classname_column);
    }
    const filtered_definitions = definitions.filter((d) => !columns_to_remove.includes(d.field));
    return filtered_definitions.map((d) => {
      d.title = _(name_conversions[d.field] || d.title);
      d.title = capitalize_string(d.title);
      d.formatter = formatters[d.field];
      d.sorter = sorters[d.field];
      d.sorterParams = sorter_params[d.field];
      d.bottomCalc = bottom_calcs[d.field];
      d.cellClick = custom_cell_clicks[d.field];
      d.maxWidth = max_widths[d.field];
      d.tooltip = table_tooltip_manager;
      d.headerTooltip = true;
      d.download = !downloads[d.field];
      return d;
    });
  };
}

function get_column_index(list, val) {
  let index = -1;
  for (const [idx, k] of Object.entries(list)) {
    if (k['field'] === val) index = idx;
  }
  return index;
}

function table_tooltip_manager(e, cell, onRendered) {
  const el = document.createElement('div');
  const field_text = {
    class_name: cell.getValue(),
    _id: _('Open test in MDF'),
  };
  el.innerText = _(field_text[cell.getColumn().getField()] || tooltip);
  return el;
}
