import { build_option } from '../glaze/options/option';
import { rpc } from './crystal_api/rpc';
import { show_support_navbar } from './navbar';
import { empty_element, show_element } from './ui';

export async function display_support_page() {
  hide_contents();
  show_support_navbar();
  rpc.public_support().then((response) => show_support_page(response));
}

function show_support_page(style) {
  const currents = {};
  for (const [k, v] of Object.entries(style)) {
    currents[k] = v.current;
  }
  const support_conf = new Support(currents, style);
  support_conf.view();
  globalThis.addEventListener(`send_support_request_${support_conf.uuid}`, () => support_conf.execute());
}

class Support extends Conf {
  constructor(currents, styles) {
    super(currents, styles);
    this.is_support_page = true;
  }

  add_search_event() {
    return false;
  }

  has_required_options() {
    return true;
  }

  get_options_list() {
    let optlist = support_page_options;
    if (this.currents.command) {
      optlist = ['command'];
    }
    const opt_list = [];
    for (let opt of Object.values(this.options)) {
      if (optlist.includes(opt['handle']) && opt) {
        opt_list.push(opt.create_template_option());
      }
    }
    const dict = {
      options: JSON.stringify({ options_list: opt_list }),
      conf_uuid: this.uuid,
    };
    return dict;
  }

  document_view_ready() {
    this.full_edit();
    clear_url_params();
  }

  get_template_file() {
    if (this.currents.command) {
      return 'support_reply.handlebars';
    }
    return 'support_page.handlebars';
  }

  view() {
    this.view_parent = document.getElementById('support_page_content');
    empty_element(this.view_parent);
    const desc = this.get_options_list();
    const handlebars = this.get_template_file();
    const support_page_template = require(`../view/${handlebars}`);
    const support_page_html = support_page_template(desc);
    this.view_parent.insertAdjacentHTML('beforeend', support_page_html);
    show_element(this.view_parent);
  }

  execute() {
    const attachments = this.options.attachments;
    if (attachments['current'].toString() != attachments['new_current'].toString()) {
      attachments.apply_changes();
    }
    const doc = this.as_doc();
    rpc.public_support({ doc: doc }).then((response) => this.execute_callback(response));
  }

  execute_callback(resp) {
    this.execute_feedback_toast(resp, true);
    this.reset();
    this.detect_changes_on_execute();
    this.apply_values_from_dict(resp.doc);
    this.view();
  }

  detect_changes_on_execute() {
    return true;
  }

  apply_values_from_dict(source) {
    this.currents['command'] = source['command'];
    const opt = build_option(this.currents['command'], this.styles['command'], this);
    this.options['command'] = opt;
    this.create_sections();
  }
}
