import { get_selected_db_id, is_genome_selected } from './hash';

function format_value(value, decimal = 4) {
  if (typeof value == 'number') {
    var a = Math.abs(value);
    if (a == Math.floor(a)) {
      value = value.toFixed(0);
    } else if (a > 1e5 || a < 1e-5) {
      value = value.toExponential(decimal);
    } else {
      value = value.toFixed(decimal);
    }
  } else if (typeof value == 'boolean') {
    if (value) {
      value = '&#10004;';
    } else {
      value = '&#10006;';
    }
  }
  return value;
}

function setUrlVars(obj) {
  var str = [];
  for (var p in obj) {
    str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
  }
  return str.join('&');
}

function to_float(value, precision = 2) {
  return parseFloat(value).toFixed(precision);
}

function round_formatter(cell) {
  return to_float(cell.getValue());
}

function cost_formatter(cell) {
  const float_value = to_float(cell.getValue());
  return float_value !== 'NaN' ? float_value : '';
}

function timestamp_formatter(cell) {
  let t = cell.getValue();
  if (t === 0) {
    console.log('ERROR timestamp');
    return 'ERROR';
  }
  if (typeof t === 'number') {
    t = new Date(t * 1000);
  }
  return t.toLocaleString();
}

function classname_formatter(cell) {
  const class_value = font_awesome_classes[cell.getValue()];
  if (class_value) {
    return `
    <div class="text-center">
      <i class='${class_value} fa-xl text-black' data-cy="class_${cell.getValue()}">
      </i>
    </div>`;
  } else {
    const icon_value = custom_svg_icons[cell.getValue()];
    return `
    <div class="h-6 w-6">
      <img src='public/${icon_value}.svg'>
    </div>`;
  }
}

function capitalize_string(string) {
  if (!string) {
    return '';
  }
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
}

function scrollToIfNeeded(element) {
  const rect = element.getBoundingClientRect();
  const isVisible = rect.top >= 0 && rect.bottom <= globalThis.innerHeight;
  if (!isVisible) {
    element.scrollIntoView();
  }
}

function get_document_hash(uid, use_document_db = false) {
  const current_user_db = get_selected_db_id().split('/')[0];
  const db_from_uid = uid.split(':')[0];
  const selected_db = use_document_db ? `${current_user_db}/${db_from_uid}` : get_selected_db_id();
  return `${selected_db}?uid=${uid}`;
}

function object_with_default(obj, default_value) {
  const obj_proxy = new Proxy(obj, {
    get: function (target, name) {
      return name in target ? target[name] : default_value;
    },
  });

  return obj_proxy;
}

function get_uid_from_row_safe(row, h) {
  let final_uid = undefined;
  if (!row || !h) return final_uid;
  const extracted_row = row['_row'];
  if (!extracted_row) return final_uid;
  const data = extracted_row['data'];
  if (!data) return final_uid;
  if (!h.handle) return final_uid;
  final_uid = data[h.handle + '_id'];
  return final_uid;
}

function wait(milliseconds) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, milliseconds);
  });
}

function remove_trailing_quotation_marks(str) {
  return str.replace(/^"/, '').replace(/"$/, '');
}

function remove_extension(filename) {
  return filename.replace(/\.[^/.]+$/, '');
}

async function sleep(milliseconds) {
  return await new Promise((r) => setTimeout(r, milliseconds));
}

function normalize(min_value, max_value) {
  return function (value) {
    if (min_value === max_value) {
      return value;
    }

    return (value - min_value) / (max_value - min_value);
  };
}

function as_number_safe(s) {
  return Number(s) || s;
}

function format_number(value, precision = {}) {
  const value_is_integer = value % 1 === 0;

  if (!precision || value_is_integer) {
    return value;
  }

  if (!value_is_integer) {
    value = as_number_safe(value);
  }

  if (typeof precision == 'number') {
    precision = `.${precision}f`;
  }
  const s = `{:${precision}}`;

  try {
    return globalThis.runPythonWithFallback(`"${s}".format(${value})`, () => s.format(value));
  } catch {
    return value;
  }
}

function get_unit(key_value) {
  const current_unit = key_value;
  const unit_present = current_unit && current_unit !== 'None';
  return unit_present ? globalThis.get_symbol(current_unit) : '';
}

function map_object(obj, func) {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, func(v)]));
}

globalThis.get_document_hash = get_document_hash;

function remove_first_slash(string) {
  if (string && string[0] === '/') {
    return string.substring(1) || '';
  }
  return string || '';
}

function remove_double_slashes(string) {
  return string?.replace(/\/+/g, '/') || '';
}

export function get_values_from(styles, doc) {
  const values = {};
  Object.keys(styles).forEach((key) => {
    const value = doc[key];
    if (value === null || value === undefined) {
      values[key] = styles[key]['factory_default'];
    } else {
      values[key] = value;
    }
  });
  return values;
}

function utf_superscript(n) {
  return ('' + n)
    .split('')
    .map((d) => {
      d = parseInt(d);
      if ([2, 3].includes(d)) {
        return String.fromCharCode(178 + d - 2);
      }
      return String.fromCharCode(8304 + d);
    })
    .join('');
}

function utf_subscript(n) {
  return ('' + n)
    .split('')
    .map((d) => String.fromCharCode(8320 + parseInt(d)))
    .join('');
}

export {
  setUrlVars,
  timestamp_formatter,
  classname_formatter,
  round_formatter,
  to_float,
  capitalize_string,
  scrollToIfNeeded,
  format_value,
  get_document_hash,
  cost_formatter,
  object_with_default,
  get_uid_from_row_safe,
  wait,
  remove_trailing_quotation_marks,
  remove_extension,
  sleep,
  normalize,
  as_number_safe,
  format_number,
  get_unit,
  map_object,
  remove_first_slash,
  remove_double_slashes,
  utf_subscript,
  utf_superscript,
};
