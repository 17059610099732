import { TabulatorFull as Tabulator } from 'tabulator-tables';
import { tabulator_options } from './tabulator_options';
import { ajaxRequestFunc } from '../ajax_request_func';
import { table_columns_definition } from './autocolumns_definitions';
import { show_document_by_uid } from '../../glaze/document';

function hide_version_column_if_empty(data, table) {
  data.every((row) => !row.version) && table.hideColumn('version');
}

export function create_tabulator(
  parent_id,
  ajax_params = { cmd: 'list' },
  ajax_request_func = ajaxRequestFunc(),
  auto_columns_definitions = table_columns_definition,
  load_all = false,
  row_click_function = (e, row) => {
    show_document_by_uid(row._row.data._id);
  },
  self_destruct_event_name = 'tabulator-self-destruct'
) {
  dispatch_event(self_destruct_event_name);

  const table = new Tabulator(
    parent_id,
    tabulator_options(ajax_params, ajax_request_func, auto_columns_definitions, load_all)
  );

  table.on('dataProcessed', function (data) {
    hide_version_column_if_empty(data, table);
  });

  table.on('rowContext', (e) => e.preventDefault());
  table.on('rowClick', row_click_function);
  table.on('rowDblClick', (e) => e.stopPropagation());

  globalThis.set_tabulator_self_destruct_listener(table, self_destruct_event_name);

  return table;
}
