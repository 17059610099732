import { manage_response } from './manage_response';

export function call(url, callback, cmd, opt, upload, user, password, logsessid, error_callback, db) {
  const xhttp = new XMLHttpRequest();

  let data = {
    user: user,
    cmd: cmd,
    password: password,
    logsessid: logsessid,
    db: db,
  };

  if (data.logsessid) {
    data.password = '';
  }
  data['compression'] = globalThis.compression;
  globalThis.compression && (xhttp.responseType = 'arraybuffer');

  data = globalThis.merge_dicts(data, opt);
  const data_as_json_string = JSON.stringify(data);

  xhttp.onload = () => manage_response(xhttp, data, callback, error_callback);

  const fd = new FormData();
  fd.append('request', data_as_json_string);
  if (upload) {
    fd.append('upload', upload);
  }
  xhttp.open('POST', url, true);
  xhttp.setRequestHeader('Authorization', 'Basic ' + btoa(`${user}:${password}`));
  xhttp.send(fd);
}
