function try_to_convert_to_number(value) {
  if (!isNaN(value)) {
    return parseFloat(value);
  }
  return value;
}

function get_vars(url = globalThis.location.href) {
  let paramString = url.split('?')[1];
  const obj = Object.fromEntries(new URLSearchParams(paramString));
  for (const k in obj) {
    if (obj[k].startsWith('[')) {
      obj[k] = obj[k]
        .replace('[', '')
        .replace(']', '')
        .split(',')
        .map((v) => try_to_convert_to_number(v));
    } else {
      obj[k] = try_to_convert_to_number(obj[k]);
    }
  }
  return obj;
}

export { get_vars };
