import { rpc } from './crystal_api/rpc';
import _ from 'underscore';

function select_last(select) {
  select.selectedIndex = select.options.length - 1;
}

function table_version_changed(event, row_id, old_version, cell) {
  const select = event.target;
  const new_version = select.selectedOptions[0].value;
  const [part, id_clean] = row_id.split(':');
  let query_id = row_id;
  if (new_version?.toString() === old_version?.toString()) {
    query_id = row_id;
  } else {
    query_id = part + ':ver#' + id_clean + '#' + new_version;
  }
  rpc
    .doc({ uid: query_id })
    .then((response) => update_table_version_data(cell.getRow(), response))
    .catch(() => {
      select_last(select);
      select.dispatchEvent(new Event('change'));
    });
}

function update_table_version_data(row, resp) {
  const old_row = row['_row']['data'];
  const new_row = {
    _id: resp['_id'],
    class_name: old_row['class_name'],
    comment: resp['comment'],
    icon_from_cn: old_row['icon_from_cn'],
    modified: resp['modified'],
    name: resp['name'],
  };
  row.update(new_row);
}

function avoid_click_to_table_row_when_selecting_a_version(sel) {
  sel.addEventListener('click', (e) => e.stopPropagation());
}

export function table_version_selector_formatter(cell) {
  const ver = cell.getValue();
  const row = cell.getRow()['_row'];
  let sel = document.createElement('span');
  sel.innerHTML = '';

  let row_id = row['data']['_id'];
  if (ver) {
    sel = document.createElement('select');
    sel.classList.add('py-0');
    sel.classList.add('w-full');
    sel.setAttribute('data-cy', `${row_id}_version`);
    avoid_click_to_table_row_when_selecting_a_version(sel);
    sel.addEventListener('change', (event) => table_version_changed(event, row_id, ver, cell));

    _.range(Math.max(0, ver - 9), ver + 1)
      .map((n) => {
        const opt = document.createElement('option');
        const current_version = n.toString();
        opt.value = current_version;
        opt.innerHTML = current_version;

        return opt;
      })
      .forEach((opt) => sel.appendChild(opt));

    select_last(sel);
  }

  return sel;
}
