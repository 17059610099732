import { show_renew_license_navbar } from './navbar';
import { show_element } from './ui';
import { rpc } from './crystal_api/rpc';
import { saveAs } from 'file-saver';
import { remove_extension } from './utils';
import { set_user_info } from './user';
import { show_ephemeral_toast } from './toast';

export async function renew_server_license() {
  hide_contents();
  show_renew_license_navbar();

  if (globalThis.get_cookie('logsessid')) {
    await rpc.user_info().then((response) => {
      set_user_info(response);
    });
  }

  const html = require('../view/renew_server_license.html').default;
  const renew_license_div = 'renew_server_license_content';
  document.getElementById(renew_license_div).replaceChildren();
  document
    .getElementById(renew_license_div)
    .insertAdjacentHTML('beforeend', html);
  show_element(renew_license_div);
}

function read_as_text(file) {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = function () {
      resolve({
        name: file.name,
        size: file.size,
        type: file.type,
        content: this.result,
      });
    };
    reader.readAsText(file);
  });
}

function upload_identifier(file) {
  return read_as_text(file)
    .then((file_info) => {
      file_info.name = remove_extension(file_info.name);
      return file_info;
    })
    .then(
      (file_info) => {
        return rpc
          .renew_server_license({
            ident: file_info.content,
            serial: file_info.name,
          })
          .then(
            (response) => {
              const content = response;
              const filename = `${file_info.name}.license`;
              const blob = new Blob([content], {
                type: 'text/plain;charset=utf-8',
              });

              saveAs(blob, filename);
              show_ephemeral_toast(
                `License file "${filename}" is being downloaded.`
              );
            },
            (error) => {}
          );
      },
      (error) => {}
    );
}

globalThis.upload_identifier = upload_identifier;
