import volume from 'convert-units/definitions/volume';
import time from 'convert-units/definitions/time';
import length from 'convert-units/definitions/length';
import mass from 'convert-units/definitions/mass';

export const length_units = {
  systems: {
    metric: {
      mm: length.systems.metric.mm,
      cm: length.systems.metric.cm,
      μm: { name: { singular: 'micron', plural: 'micron' }, to_anchor: length.systems.metric.μm.to_anchor },
    },
  },
  anchors: {
    metric: length.anchors.metric,
  },
};

export const time_units = {
  systems: {
    SI: {
      s: time.systems.SI.s,
      min: time.systems.SI.min,
      h: time.systems.SI.h,
      d: time.systems.SI.d,
    },
  },
};

export const volume_units = {
  systems: {
    metric: {
      mm3: volume.systems.metric.mm3,
      cm3: volume.systems.metric.cm3,
      μm3: {
        name: {
          singular: 'micron^3',
          plural: 'micron^3',
        },
        to_anchor: 1 / 1000000000000000,
      },
    },
  },
  anchors: {
    metric: volume.anchors.metric,
  },
};

export const mass_units = {
  systems: { metric: { mg: mass.systems.metric.mg, g: mass.systems.metric.g, kg: mass.systems.metric.kg } },
  anchors: { metric: mass.anchors.metric },
};

export const percentage_units = {
  custom: {
    systems: {
      percentage: {
        '%': {
          name: { singular: 'percent', plural: 'percent' },
          to_anchor: 1 / 100,
        },
        '‰': {
          name: { singular: 'perthousand', plural: 'perthousand' },
          to_anchor: 1 / 1000,
        },
        ppm: {
          name: { singular: 'permillion', plural: 'permillion' },
          to_anchor: 1e-6,
        },
      },
    },
    anchors: {},
  },
};

export const molarMass_units = {
  custom: {
    systems: {
      molarMass: {
        'g/mol': {
          name: { singular: 'gram/mole', plural: 'gram/mole' },
          to_anchor: 1,
        },
        'kg/mol': {
          name: { singular: 'kilogram/mole', plural: 'kilogram/mole' },
          to_anchor: 1000,
        },
      },
    },
    anchors: {},
  },
};
