function all_report_menu_icons_visibilities(conf) {
  const is_new_document = !!(conf.is_new() && !conf.is_version && !conf.is_search());

  return {
    save_edits: conf.is_dirty() && !is_new_document,
    cancel_edits: conf.is_dirty() && !is_new_document,
    show_quartz_icon: conf.show_quartz_icon(),
  };
}

let last_menu = '';
export function create_menu_report(parent, conf) {
  console.log('Creating document menu icons');
  const current_menu = document.getElementById(`report_${conf.objid}_menu`);
  if (!current_menu) {
    last_menu = '';
  }
  const current_menu_data = JSON.stringify(all_report_menu_icons_visibilities(conf));
  if (last_menu === current_menu_data) {
    return;
  }

  const document_menu = {
    doc_menu_id: `report_${conf.objid}_menu`,
    x_data: current_menu_data,
    report_id: conf.currents['testid'],
    report_uid: conf.uid,
    report_curveHash: conf.currents.curveHash,
  };

  last_menu = current_menu_data;
  current_menu?.remove();
  const doc_menu_template = require('../view/document/report_buttons.handlebars');
  const doc_menu_html = doc_menu_template(document_menu);

  document.getElementById(parent)?.insertAdjacentHTML('beforeend', doc_menu_html);
}
