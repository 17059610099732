import { decode_response } from './decode_response';
import pako from 'pako';
import { remove_trailing_quotation_marks } from '../utils';

export function manage_response(xhttp, data, callback, error_callback, response_decoder = decode_response) {
  const dec = response_decoder(xhttp, data);
  const response = dec.response;

  if (data.compression && dec.body) {
    dec.body = JSON.parse(pako.inflate(dec.body, { to: 'string' }));
  }

  const status_code = `${xhttp.status}`;
  if (status_code.startsWith('4') || status_code.startsWith('5')) {
    const error_message = remove_trailing_quotation_marks(dec.body?.error || dec.body);
    return error_callback({
      status: xhttp.status,
      message: error_message,
    });
  }

  if (response.logsessid) {
    globalThis.set_cookie('logsessid', response.logsessid, 7);
  }

  if (status_code.startsWith('2')) {
    return callback(dec.body || dec.response, data);
  }

  return error_callback({
    status: xhttp.status,
    message: `Cannot manage status code ${xhttp.status}`,
  });
}
