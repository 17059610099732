export class UserCompany extends Conf {
  constructor(currents, styles) {
    super(currents, styles);
    this.is_usercompany_view = true;
  }

  get_document_desc() {
    const desc = {
      uuid: this.uuid,
      x_data: '{ main:true, Admin:false }',
      usercompany: this.currents.name,
    };
    return desc;
  }

  get_view_parent() {
    return 'company_content';
  }

  show_action_buttons() {
    return false;
  }

  get_template_file() {
    return 'company.handlebars';
  }
}
