import { User } from '../glaze/user.js';
import { rpc } from './crystal_api/rpc';
import { empty_element, hide_element, remove_element, show_element } from './ui';
import { get_hash, get_selected_db_id } from './hash';
import { get_databases } from './permission';
import { show_ephemeral_toast } from './toast';

const client_hashes = [];
const instrument_hashes = [];

function user_reset_password(shown_before_id, resp) {
  empty_element('forgot-pw_div');
  empty_element(shown_before_id);
  show_element(shown_before_id);
  const shown_before = document.getElementById(shown_before_id);
  if (resp.error) {
    shown_before.appendChild(document.createTextNode(resp.error));
    return false;
  }
  shown_before.appendChild(document.createTextNode('Check your email box to reset the password.'));
  return;
}

function forgot_pw_data_error() {
  const new_password = document.getElementById('forgot_password_new_pw');
  const confirm_password = document.getElementById('forgot_password_confirm_pw');
  return new_password.value !== confirm_password.value ? "Password don't match" : false;
}

function confirm_password_reset(shown_before) {
  const doc = {};
  const data_error = forgot_pw_data_error();
  if (!data_error) {
    doc.email = document.getElementById('forgot_password_email').value.toLowerCase();
    doc.newPassword = document.getElementById('forgot_password_new_pw').value;
    doc.confirmPassword = document.getElementById('forgot_password_confirm_pw').value;
    rpc.user_reset(doc).then((response) => user_reset_password(shown_before, response));
  } else {
    show_ephemeral_toast(data_error, 'Info');
  }
}

function reset_forgot_password() {
  remove_element('forgot-pw_div');
  const parent = 'app-mount-point';
  hide_element(parent);
  const desc = { shown_before: parent };
  const document_template = require('../view/document/forgot_password.handlebars');
  const document_html = document_template(desc);

  document.body.insertAdjacentHTML('beforeend', document_html);
}

function create_new_user(resp) {
  const opts = globalThis.new_user_form_options;
  const cur = {};
  for (const [k, v] of Object.entries(resp)) {
    if (opts.includes(k)) {
      cur[k] = v.current;
    }
  }
  cur['style'] = resp['style'].current;
  const sign_in_form = new User(cur, resp);
  globalThis.new_user_form = sign_in_form;
  create_new_user_form();
}

function cancel_new_user(shown_before) {
  remove_element('forgot-pw_div');
  if (shown_before === 'login_cont') {
    globalThis.do_logout();
  } else {
    remove_element('sign-in_div');
    show_element(shown_before);
    location.hash = get_selected_db_id();
  }
}

function create_new_user_form() {
  const parent = 'login_cont';
  hide_element(parent);
  let opts = globalThis.new_user_form_options;
  let opt_list = [];
  for (let o of opts) {
    let opt = globalThis.new_user_form.options[o];
    opt_list.push(opt.create_template_option());
  }
  const desc = {
    options: JSON.stringify({ options_list: opt_list }),
    shown_before: parent,
  };
  const document_template = require('../view/document/new_user.handlebars');
  const document_html = document_template(desc);
  document.body.insertAdjacentHTML('beforeend', document_html);
}

function new_user_input_value(handle) {
  return document.getElementById(`new-user-input-${handle}`).value;
}

function new_user_data_error() {
  const new_password = new_user_input_value('password');
  const confirm_password = new_user_input_value('confirmPassword');
  return new_password !== confirm_password ? "Password don't match" : false;
}

function confirm_new_user(shown_before) {
  const form = globalThis.new_user_form;
  var doc = {};
  for (let [k, v] of Object.entries(form.options)) {
    doc[k] = v.current;
  }
  const data_error = new_user_data_error();
  if (!data_error) {
    doc.name = new_user_input_value('name');
    doc.email = new_user_input_value('email')?.toLowerCase();
    doc.password = new_user_input_value('password');
    doc.confirmPassword = new_user_input_value('confirmPassword');
    doc.phone = new_user_input_value('phone');
    doc.company_string = new_user_input_value('company_string');
    doc.country = new_user_input_value('country');
    doc.comment = new_user_input_value('comment');
    let c = form.options['captcha'].current;
    c[1] = new_user_input_value('captcha');
    doc.captcha = c;
    rpc.user_new({ doc: doc }).then((response) => new_user_callback(shown_before, response));
  } else {
    show_ephemeral_toast(data_error, 'Info');
  }
}

function new_user_callback(shown_before_id, resp) {
  remove_element('sign-in_div');
  empty_element(shown_before_id);
  show_element(shown_before_id);
  const shown_before = document.getElementById(shown_before_id);
  if (resp.error) {
    shown_before.appendChild(document.createTextNode(resp.error));
    return false;
  }
  shown_before.appendChild(
    document.createTextNode('Check your email box and open the link in the email to complete the registration.')
  );
  return;
}

function set_user_info(ui) {
  console.log('set_user_info');
  globalThis.user_data.info = ui;
  globalThis.user_data.databases = get_databases();
  location.hash = get_hash(globalThis.user_data.databases);
}

function create_new_user_from_admin() {
  console.log('admin new user');
  rpc.user_new().then((response) => create_new_user(response));
}

globalThis.reset_forgot_password = reset_forgot_password;
globalThis.confirm_password_reset = confirm_password_reset;
globalThis.confirm_new_user = confirm_new_user;
globalThis.cancel_new_user = cancel_new_user;

export { set_user_info, create_new_user_from_admin, create_new_user, client_hashes, instrument_hashes };
