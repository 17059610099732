import configMeasurements from 'convert-units';
import { length_units, mass_units, molarMass_units, percentage_units, time_units, volume_units } from './configure_units';
import temperature from 'convert-units/definitions/temperature';

function convert_value(value, from, to) {
  from = cvt[from] || from;
  to = cvt[to] || to;
  if (!from || !to) return value;
  const val_to = convert(value).from(from).to(to);
  return val_to;
}

function get_unit_conversion_possibilities(unit) {
  unit = cvt[unit];
  let possibilities = [];
  if (unit && unit !== 'None') {
    possibilities = convert().from(unit).possibilities();
  }
  return possibilities;
}

const length = length_units;
const time = time_units;
const volume = volume_units;
const mass = mass_units;
const percentage = percentage_units['custom'];
const molarMass = molarMass_units['custom'];

globalThis.convert = configMeasurements({ length, time, volume, percentage, mass, temperature, molarMass });
globalThis.convert_value = convert_value;

globalThis.cvt = {};
globalThis.icvt = {};
convert()
  .list()
  .forEach((a) => {
    const singular = a['singular'].toLowerCase().replace('degree ', '');
    cvt[singular.replace('metre', 'meter')] = a['abbr'];
    cvt[singular] = a['abbr'];
    cvt[singular.replace('litre', 'liter')] = a['abbr'];
    cvt[singular.replace(' per ', '/')] = a['abbr'];
    icvt[a['abbr']] = singular;
  });

export { convert_value, get_unit_conversion_possibilities };
