import { refresh_material_table } from '../glaze/material_table_home';
import { update_tests } from '../ligo/search_report';
import { get_selected_db_id } from './hash';
require('../glaze/material_table_home');
require('./global_functions');

let current_refresh_function = refresh_material_table;
let current_db_id = get_selected_db_id();

function reset_fast_search() {
  const new_db_id = get_selected_db_id();
  if (new_db_id !== current_db_id) {
    document.getElementById('search_in_home')._x_model.set('');
  }
  current_db_id = new_db_id;
}

export function switch_to_report_search() {
  globalThis.current_search_event = 'perform-search';
  reset_fast_search();
  current_refresh_function = update_tests;
}

export function switch_to_genome_search() {
  globalThis.current_search_event = 'perform-search';
  reset_fast_search();
  current_refresh_function = refresh_material_table;
}

globalThis.refresh_table = (params) => {
  return current_refresh_function(params);
};
