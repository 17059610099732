const { DateTime } = require('luxon');

const t_hour = 3600;
const t_day = t_hour * 24;
const t_week = t_day * 7;
const t_month = t_day * 30;
const t_quarter = t_day * 90;
const t_semester = t_day * 182;
const t_year = t_day * 365;

const first_possible_day = DateTime.utc(2015, 0, 1);

export { t_day, t_hour, first_possible_day };
