import { show_content } from './contents_visibility';
import { rpc } from './crystal_api/rpc';
import { show_user_info_doc, show_usercompany_doc } from './look';
import { User } from '../glaze/user';
import { create_genome_home_page, empty_element } from './ui';
import { get_vars } from './url';
import { new_document_from_navbar, show_document_by_uid } from '../glaze/document';

import {
  hide_navbar_elems,
  init_company_navbar,
  init_genome_navbar,
  init_ligo_navbar,
  init_user_navbar,
} from './navbar';
import { switch_to_genome_search, switch_to_report_search } from './search';
import { UserCompany } from './usercompany';
import { show_report } from '../ligo/report';
import { is_genome_selected } from './hash';
import { ligo_search_report_using_queryhash } from '../ligo/search_report_functions';
import { get_cams, get_resolution } from '../mdf/mdf_rpc';
import { image_selection } from './image_selection_tools';
import { sleep } from './utils';

const scales = {};

export function display_user() {
  empty_element('user_content');
  init_user_navbar();
  rpc
    .doc({
      uid: 'def:Style#User#0',
      db: globalThis.get_cookie(globalThis.last_db_cookie_name) || 'demo',
    })
    .then((style) => show_user_info_doc(new User(globalThis.user_data.info, style)));
}

export function display_usercompany() {
  empty_element('company_content');
  init_company_navbar();
  rpc.doc({ uid: globalThis.user_data['info']['parent'][0], db: 'users' }).then((doc) => {
    rpc
      .doc({
        uid: 'def:Style#UserCompany#0',
        db: globalThis.get_cookie(globalThis.last_db_cookie_name) || 'demo',
      })
      .then((style) => show_usercompany_doc(new UserCompany(doc, style)));
  });
}

export function display_genome() {
  switch_to_genome_search();
  hide_navbar_elems();

  empty_element('table');
  const vars = get_vars();
  const uid = vars['uid'] || '';
  globalThis.report_search_doc = {};
  init_genome_navbar();
  show_content(document.getElementById('content'));

  rpc
    .doc({
      uid: 'def:Style#SearchGenome#0',
    })
    .then((style) => create_genome_home_page(style, {}))
    .then(() => {
      empty_element('document_container');
      globalThis.dispatchEvent(
        new CustomEvent('perform-search', {
          detail: { should_reset_query_hash: false },
        })
      );
    })
    .then(() => show_document_by_uid(uid));
}

export function display_reports() {
  empty_element('document_container');
  switch_to_report_search();
  globalThis.tests_report_table = undefined;
  globalThis.data_already_loaded = false;
  init_ligo_navbar();
  show_content(document.getElementById('content'));

  rpc
    .doc({
      uid: 'def:Style#SearchReport#0',
    })
    .then((response) => ligo_search_report_using_queryhash(response));
  globalThis.report_search_doc = false;

  const uid = get_vars()['uid'];
  if (uid) {
    show_report(uid);
  }
}

export function display_new_document_creation() {
  refresh_table()
  const should_refresh_page = !document.getElementById('document_container');
  if (should_refresh_page) {
    is_genome_selected() ? display_genome() : display_reports();
  }
  const id = get_parameter('docId');
  let type = id.split('#')[1];
  if (type.startsWith('Material')) type = type.substring(8).toLowerCase();
  if (type.startsWith('Analysis')) type = type.substring(8);
  rpc
    .doc({
      uid: id,
    })
    .then((response) => new_document_from_navbar('', type, response));
}

function worker_name(cam_id) {
  return `${cam_id}_worker`;
}

export async function display_mdf() {
  hide_navbar_elems();
  const content = document.getElementById('content');
  content?.classList.remove('hidden');
  const cams_template = require('../mdf/view/cam.handlebars');
  const [cams, instrument] = await get_cams();
  const sample_urls = cams.reduce((acc, cur) => {
    acc[cur.id] = cur.sample_url;
    return acc;
  }, {});
  const data_for_handlebars = { cams: JSON.stringify(cams) };
  const image_html = cams_template(data_for_handlebars);
  content?.insertAdjacentHTML('beforeend', image_html);
  const server_url = globalThis.get_parameter('server_url') || globalThis.mdf_config.url;
  const workers_container = {};
  for (const cam of cams) {
    await new_worker(workers_container, cam.id, cam.sample_url, server_url, instrument);
  }

  globalThis.global_start_worker = (cam_id) => {
    workers_container[worker_name(cam_id)].postMessage({
      server_url: server_url,
      cam_id: cam_id,
      sample_url: sample_urls[cam_id],
      scale: scales[cam_id],
      instrument: instrument,
      reset: true,
    });
    workers_container[worker_name(cam_id)].running = true;
    document.getElementById(`${cam_id}_start`).disabled = true;
    setTimeout(() => (document.getElementById(`${cam_id}_selection_box`).hidden = false), 100);
  };

  globalThis.global_stop_worker = async (cam_id) => {
    workers_container[worker_name(cam_id)].postMessage({ cam_id: cam_id, stop: true });
    workers_container[worker_name(cam_id)].running = false;
    const empty_image = require('./media/empty.png');
    setTimeout(() => (document.getElementById(cam_id).src = empty_image), 100);
    document.getElementById(`${cam_id}_start`).disabled = false;
  };
}

async function new_worker(workers_container, cam_id, sample_url, server_url, instrument) {
  const resolution = await get_resolution(cam_id);
  const [w, h] = resolution;
  const image = document.getElementById(cam_id);
  let selection_box;
  workers_container[worker_name(cam_id)] = new Worker(new URL('../mdf/mdf_cam_worker.js', import.meta.url));
  const cam_container = document.getElementById(`${cam_id}_container`);
  const resize = async () => {
    const image_container = document.getElementById(`${cam_id}_image_container`);
    const scale_h = h / image_container.getBoundingClientRect().height;
    const scale_w = w / image_container.getBoundingClientRect().width;
    scales[cam_id] = Math.max(scale_h, scale_w);

    selection_box = image_selection(
      cam_id,
      sample_url,
      image_container,
      workers_container[worker_name(cam_id)],
      server_url,
      scales[cam_id]
    );

    if (workers_container[worker_name(cam_id)].running) {
      image.hidden = true;
      selection_box.hidden = true;
      workers_container[worker_name(cam_id)].postMessage({ cam_id: cam_id, stop: true });
      await sleep(400);
      workers_container[worker_name(cam_id)].postMessage({
        server_url: server_url,
        cam_id: cam_id,
        sample_url: sample_url,
        instrument: instrument,
        scale: scales[cam_id],
        reset: true,
      });
      setTimeout(() => {
        image.hidden = false;
        selection_box.hidden = false;
      }, 300);
    }
  };
  const resize_observer = new ResizeObserver((entries) => {
    for (let entry of entries) {
      resize();
    }
  });

  workers_container[worker_name(cam_id)].onmessage = (message) => {
    if (!message.data) return;

    const frame = message.data.frame;
    image.style.left = `${frame[0]}px`;
    image.style.top = `${frame[1]}px`;
    image.style.width = `${frame[2]}px`;
    image.style.height = `${frame[3]}px`;
    image.src = URL.createObjectURL(message.data.image);

    if (workers_container[worker_name(cam_id)].read_server_roi) {
      const image_position = image.getBoundingClientRect();
      selection_box.style.left = `${image_position.x}px`;
      selection_box.style.top = `${image_position.y}px`;
      selection_box.style.width = `${image_position.width}px`;
      selection_box.style.height = `${image_position.height}px`;
    }
  };

  resize_observer.observe(cam_container);
}

globalThis.toggle_full_screen_cam = (cam_id) => {
  document.getElementById(`${cam_id}_selection_box`).hidden = true;
  const cam_container = document.getElementById(`${cam_id}_container`);
  if (!document.fullscreenElement) {
    cam_container.requestFullscreen().catch((err) => {
      alert('Error attempting to enable full-screen mode: ' + err.message);
    });
  } else {
    document.exitFullscreen();
  }
};
