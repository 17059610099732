import { sleep } from './utils';
import { is_report_selected } from './hash';
import { get_vars } from './url';
import { refresh_overlay_table } from './tabulator/overlay_table';

globalThis.set_cookie = (name, value) => {
  globalThis.localStorage.setItem(name, value);
};

globalThis.get_cookie = (name) => {
  return globalThis.localStorage.getItem(name);
};

globalThis.delete_cookie = (name) => {
  globalThis.localStorage.removeItem(name);
};

globalThis.discard_dirty_state = () => {
  if (!some_option_is_dirty()) {
    return true;
  }

  if (
    globalThis.confirm(`Continue?
Changes you made may not be saved.`)
  ) {
    clean_dirty_options();
    return true;
  }
  return false;
};

globalThis.object_properties = (dict, excl = []) => {
  var properties = [];
  for (var k in dict) {
    if (excl.includes(k)) {
      continue;
    }
    if (dict.hasOwnProperty(k)) {
      properties.push({ property: k, value: dict[k] });
    }
  }
  return properties;
};

const separator = '!$%&_json_separator_&%$!';
globalThis.v_separator = new Uint8Array(
  separator.split('').map(function (x) {
    return x.charCodeAt(0);
  })
);

globalThis.merge_dicts = (A, B) => {
  const out = {};
  for (let k in B) {
    if (B.hasOwnProperty(k)) {
      out[k] = B[k];
    }
  }
  for (let k in A) {
    if (A.hasOwnProperty(k)) {
      out[k] = A[k];
    }
  }
  return out;
};

globalThis.dispatch_route_changed = () => {
  globalThis.dispatchEvent(new Event('route_changed'));
};

globalThis.runPythonWithFallback = (pythonCommand, javascriptFallback) => {
  return globalThis.pyodide ? globalThis.pyodide.runPython(pythonCommand) : javascriptFallback();
};

globalThis.getElementByIdWithRetry = async (id, count = 5) => {
  let element = undefined;
  let current_count = 0;
  while (!element && current_count < count) {
    element = document.getElementById(id);
    current_count++;
    await sleep(50);
  }
  return element;
};

globalThis.get_URLSearchParams = () => {
  return new URLSearchParams(globalThis.location.href.split('?')[1]);
};
globalThis.get_parameter = (parameter_name) => {
  return get_URLSearchParams().get(parameter_name);
};

function set_query_param_private(param, value, update_location_function) {
  const current_parameters = get_URLSearchParams();
  if (!value) {
    current_parameters.delete(param);
  } else {
    current_parameters.set(param, value);
  }
  const url = location.hash.split('?')[0];

  const loc = url + '?' + current_parameters.toString();
  update_location_function(loc);
  return loc;
}

globalThis.set_query_param = (param, value) => {
  return set_query_param_private(param, value, (loc) => globalThis.history.replaceState(loc, '', loc));
};

globalThis.set_query_param_updating_history = (param, value) => {
  return set_query_param_private(param, value, (loc) => globalThis.history.pushState(loc, '', loc));
};

globalThis.reset_queryHash = () => set_query_param('queryHash');

globalThis.clear_url_params = () => {
  const vars = get_vars();
  delete vars['search'];
  Object.keys(vars).forEach((p) => set_query_param(p));
};

globalThis.dispatch_event = (event_name) => {
  globalThis.dispatchEvent(new Event(event_name));
};

globalThis.destroy_tabulator = (tabulator_instance) => {
  tabulator_instance.options.ajaxRequestFunc = () => {};
  tabulator_instance.options.ajaxParams = () => {
    return { cmd: 'no_op' };
  };
  tabulator_instance.destroy();
};

globalThis.set_tabulator_self_destruct_listener = (tabulator_instance, event_name) => {
  globalThis.addEventListener(event_name, () => destroy_tabulator(tabulator_instance), { once: true });
};

globalThis.create_option_html = (option) => {
  let option_type = option.get_template_name();
  const view_template_file = `option_${option_type}.handlebars`;
  const document_template = require(`../view/option/currents/${view_template_file}`);
  return document_template(option);
};

globalThis.onbeforeunload = function () {
  if (some_option_is_dirty()) {
    return '';
  }
};

globalThis.should_show_load_all_button = () => {
  return is_report_selected();
};

globalThis.safe_invoke = (function_to_invoke, receiver, ...args) => {
  if (function_to_invoke in receiver) {
    receiver[function_to_invoke](...args);
  }
};

globalThis.get_symbol = (key) => {
  const symbols = {
    micron: 'μm',
    nanometer: 'nm',
    millimeter: 'mm',
    centimeter: 'cm',
    meter: 'm',
    'micron^3': 'μm³',
    'micron^2': 'μm²',
    'nanometer^3': 'nm³',
    'nanometer^2': 'nm²',
    'millimeter^3': 'mm³',
    'millimeter^2': 'mm²',
    degree: '°',
    radian: 'rad',
    celsius: '°C',
    kelvin: '°K',
    fahrenheit: '°F',
    'celsius/minute': '°C/min',
    second: 's',
    minute: 'min',
    hour: 'h',
    day: 'd',
    millisecond: 'ms',
    microsecond: 'μs',
    percent: '%',
    permille: '‰',
    'percent/minute': '%/min',
    'permille/minute': '‰/min',
    hertz: 'Hz',
    kilohertz: 'kHz',
    byte: 'B',
    kilobyte: 'KB',
    megabyte: 'MB',
    gigabyte: 'GB',
    poise: 'P',
    'pascal/s': 'Pa/s',
    'cm^2/second': 'cm²s⁻¹',
    'm^2/second': 'm²s⁻¹',
    'mm^2/second': 'mm²s⁻¹',
    'gram/cm^3': 'g/cm³',
    'cm^2*kelvin/watt': 'cm²K/W',
    'joule/gram*kelvin': 'J/gK',
    gram: 'g',
    milligram: 'mg',
    kilogram: 'kg',
    'gram/centimeter3': 'g/cm³',
    volt: 'V',
    millivolt: 'mV',
    ohm: 'Ω',
    milliohm: 'mΩ',
    megaohm: 'MΩ',
    'volt/second': 'V/s',
    '1/second': '1/s',
    '1/celsius': '°C⁻¹',
    '1/fahrenheit': '°F⁻¹',
    '1/kelvin': '°K⁻¹',
    ampere: 'A',
    'kelvin/minute': '°K/min',
    'fahrenheit/minute': '°F/min',
    pixel: 'px',
    angstrom: 'Å',
    euro: '€',
    dollar: '$',
    'newton/meter': 'N/m',
    'meter2/kilogram': 'm²/kg',
    'newton second/meter2': 'Ns/m²',
    'gram/mole': 'g/mol',
    'kilogram/mole': 'kg/mol',
  };
  return symbols[key] || key;
};

globalThis.refresh_search_table = (option_uuid) => {
  let table = refresh_overlay_table(option_uuid);
  return new Promise((resolve) => {
    resolve(table);
  });
};

globalThis.check_input_validity = (max_elem, uid) => {
  const min = parseFloat(document.getElementById(`${uid}_input_min`).value);
  const max = parseFloat(max_elem.value);
  let err = '';
  if (max && min && min > max) {
    err = _('Maximum value must be greater than minimum value');
  }
  max_elem.setCustomValidity(err);
};

globalThis.get_icon_class_name = (id, class_name) => {
  const icon = [':Field', ':Compare'].find((el) => id.includes(el));
  return icon && icon.startsWith(':') ? icon.split(':')[1] : class_name;
};

globalThis.get_disable_save_btn = () => {
  return Array.from(document.getElementsByName('edited_option_checked')).every((el) => !el.checked);
};
