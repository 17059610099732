import { v4 as uuid_v4 } from 'uuid';

const obj_registry = {};

export function add_controller(controller) {
  const uuid = uuid_v4();
  controller.uuid = uuid;
  obj_registry[uuid] = controller;
}

export function remove_controller(controller) {
  delete obj_registry[controller.uuid];
}

function get_controller(uuid, template_override) {
  const option = obj_registry[uuid];
  if (template_override && option) {
    option.template_override = template_override;
  }
  return option;
}

function some_option_is_dirty() {
  return Object.values(obj_registry).some((option) => option.conf?.is_dirty());
}

function clean_dirty_options() {
  return Object.keys(obj_registry).forEach((key) => {
    if (obj_registry[key].conf?.is_dirty()) {
      delete obj_registry[key];
    }
  });
}

globalThis.get_controller = get_controller;
globalThis.some_option_is_dirty = some_option_is_dirty;
globalThis.clean_dirty_options = clean_dirty_options;
