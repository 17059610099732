import { genome_parse_new_data } from '../../glaze/material_table';
import { tests_parse_new_data } from '../../ligo/tests';
import { ajaxRequestFunc } from '../ajax_request_func';
import { is_genome_selected } from '../hash';
import { add_doc_to_overlay_table } from './add_doc_to_table';
import { table_columns_definition } from './autocolumns_definitions';
import { create_tabulator } from './tabulator_factory';

export function edit_option_overlay_table(last_query, option_uuid) {
  const option = get_controller(option_uuid);
  last_query['classes'] = option.header[0].style['target'];
  const options_config = {
    cmd: 'list',
    request_func: is_genome_selected() ? genome_parse_new_data : tests_parse_new_data,
  };
  return create_tabulator(
    `#${option['handle']}_search_div_container`,
    { cmd: options_config['cmd'] },
    ajaxRequestFunc(options_config['request_func'], last_query, true),
    table_columns_definition(_('Click to add'), true),
    false,
    (e, row) => add_doc_to_overlay_table(row, option),
    'self_destruct_overlay'
  );
}

export function refresh_overlay_table(option_uuid) {
  const text = document.getElementById('overlay_search_input').value;
  const query = is_genome_selected() ? { nameComment: text } : { title: text };
  return edit_option_overlay_table(query, option_uuid);
}
