export function decode_response(xhttp, data0) {
  let body = undefined;
  let response = undefined;

  if (xhttp.responseType === 'arraybuffer') {
    const res = new Uint8Array(xhttp.response);
    const i = find_index_of_json_separator(res);
    let jres = res;
    if (i > 0) {
      jres = res.subarray(0, i);
    }
    if (jres.length) {
      response = JSON.parse(decoder.decode(jres));
      if (i > 0) {
        body = res.subarray(i + globalThis.v_separator.length, res.length);
      } else {
        body = response['body'];
        data0.compression = false;
      }
    }
  } else {
    response = JSON.parse(xhttp.response);
    body = response['body'];
  }
  return { response: response, body: body };
}

function find_index_of_json_separator(unsigned_int_array) {
  let separator_length = globalThis.v_separator.length;
  let l = unsigned_int_array.length + 1 - separator_length;

  let i = 0;
  loop: for (; i < l; i++) {
    for (let j = 0; j < separator_length; j++)
      if (unsigned_int_array[i + j] !== globalThis.v_separator[j]) continue loop;
    return i;
  }
  return -1;
}
