import { hide_element, show_element } from './ui';

const contents_ids = [
  'content',
  'user_content',
  'company_content',
  'login_cont',
  'renew_server_license_content',
  'admin_page_content',
  'support_page_content'
];

export function hide_contents() {
  for (const id of contents_ids) {
    hide_element(id);
  }
}

export function show_content(content_id) {
  hide_contents();
  show_element(content_id);
}

globalThis.hide_contents = hide_contents;
