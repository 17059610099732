function create_modal(dialog, buttons_desc, cancel = true) {
  const dialog_template = require('../view/dialog.handlebars');
  const desc = {
    dialog_id: dialog['id'],
    title: dialog['title'],
    content: dialog['content'],
    button: buttons_desc,
    show_cancel: cancel,
  };
  const dialog_html = dialog_template(desc);
  document.body.insertAdjacentHTML('beforeend', dialog_html);
  buttons_desc.forEach((btn) => {
    document.getElementById(btn['button_id']).addEventListener('click', () => {
      btn['func']();
    });
  });
}

function dummy_element() {
  return {
    classList: {
      add: () => {},
      remove: () => {},
    },
  };
}

export { create_modal, dummy_element };
