globalThis.pyodide_enabled = parseInt(process.env.PYODIDE_ENABLED);
globalThis.material_table_should_not_be_filtered = false;
globalThis.last_db_cookie_name = 'last_db';
globalThis.user_data = { info: {}, password: '', permission: {} };
globalThis.rpc_config ||= { url: '/rpc/rpc.php' };
globalThis.global_counter ||= {
  current: 0,
  next() {
    return ++this.current;
  },
};

globalThis.tests_table_list = [];
globalThis.material_classes ||= {};
globalThis.option_types ||= {};

if (
  location.protocol == 'file:' ||
  location.hostname == 'localhost' ||
  location.hostname == '127.0.0.1' ||
  location.hostname.startsWith('192.168.0.')
) {
  console.log('Detected offline usage');
  globalThis.rpc_config.url = 'http://' + location.hostname + ':8003/rpc/rpc.php';
}

globalThis.rpc_config.url = process.env.RPC_URL || globalThis.rpc_config.url;

globalThis.compression = process.env.COMPRESSION != 'false';

globalThis.custom_svg_icons = {
  ReportHorizontal: 'small_horizontal',
  ReportMicroscope: 'small_hsm',
  ReportFlex: 'small_flex',
  ReportFlex3: 'small_flex3',
  ReportKiln: 'small_kiln',
  ReportVertical: 'small_vertical',
  Conf: 'mdf',
};

globalThis.all_materials_classes = [
  'MaterialRaw',
  'MaterialSemi',
  'MaterialElement',
  'MaterialCompound',
  'MaterialOxide',
  'MaterialProduct',
  'MaterialMix',
  'Formulation',
];

globalThis.default_tabulator_pageSize = 5;

globalThis.new_user_form_options = [
  'name',
  'comment',
  'phone',
  'company_string',
  'country',
  'email',
  'password',
  'confirmPassword',
  'captcha',
];

globalThis.support_page_options = ['name', 'serial', 'company', 'request', 'attachments', 'phone', 'email', 'captcha'];

globalThis.empty_data_placeholder = '---';

globalThis.comparing = false;

globalThis.font_awesome_classes = {
  MaterialCompound: 'fa-solid fa-prescription-bottle',
  MaterialElement: 'fa-brands fa-react',
  MaterialRaw: 'fa-solid fa-mound',
  MaterialMix: 'fa-solid fa-mortar-pestle',
  MaterialOxide: 'fa-solid fa-tablets',
  MaterialProduct: 'fa-solid fa-boxes-stacked',
  MaterialSemi: 'fa-solid fa-bowl-rice',
  MaterialDummy: 'fa-solid fa-question',
  Formulation: 'fa-solid fa-wand-magic-sparkles',
  Compare: 'fa-solid fa-scale-balanced',
  Analysis: 'fa-solid fa-flask',
  Batch: 'fa-solid fa-vials',
  Sample: 'fa-solid fa-vial',
  UseCase: 'fa-solid fa-wrench',
  PhysicalForm: 'fa-solid fa-snowflake',
  CustomLabel: 'fa-solid fa-tag',
  SearchGenome: 'fa-solid fa-magnifying-glass',
  SearchReport: 'fa-solid fa-magnifying-glass',
  Field: 'fa-solid fa-calculator',
  PreparationMethod: 'fa-solid fa-receipt',
  Tag: 'fa-solid fa-hashtag',
  Agent: 'fa-solid fa-user',
  Place: 'fa-solid fa-location-dot',
  Company: 'fa-solid fa-industry',
  Logistic: 'fa-solid fa-truck',
  ModelBase: 'fa-solid fa-chart-line',
  ModelPlot: 'fa-solid fa-chart-line',
  ModelCTE: 'fa-solid fa-chart-line',
  ModelCoupling: 'fa-solid fa-chart-line',
  ModelViscosity: 'fa-solid fa-chart-line',
  ModelSintering: 'fa-solid fa-chart-line',
  ModelQuartz: 'fa-solid fa-gem',
};

require('./global_functions');

globalThis.default_page_size =
  parseInt(get_cookie('tabulator_page_size')) || parseInt(process.env.DEFAULT_TABULATOR_PAGE_SIZE);

globalThis.current_search_event = '';
globalThis.comparing_classes = [];

globalThis.perform_search_listenere_already_added = true;

globalThis.last_query = {};

globalThis.mdf_config ||= { url: 'https://localhost:3880' };
