function export_header_style() {
  return {
    alignment: { wrapText: false },
    fill: { fgColor: { rgb: 'FFEFEFEF' } },
    border: {
      top: { style: 'thin', color: { rgb: 'FFCFCFCF' } },
      bottom: { style: 'thin', color: { rgb: 'FFCFCFCF' } },
      left: { style: 'thin', color: { rgb: 'FFCFCFCF' } },
      right: { style: 'thin', color: { rgb: 'FFCFCFCF' } },
    },
    font: { bold: true },
  };
}

function export_title_style() {
  return { font: { bold: true }, fill: { fgColor: { rgb: 'FFCFCFCF' } } };
}

export { export_header_style, export_title_style };
